import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './wysiwyg.module.scss';

const WYSIWYG = ({ children, className, body, dangerouslyRender = false }) => {

  const data = useStaticQuery(graphql`
    query WYSIWYGQuery {
      site {
        siteMetadata {
          drupalSiteURL
        }
      }
    }
  `);

  const transform = (node, index) => {
    if (node.type === 'tag' && node.name === 'img') {
      const srcData = {
        site: data.site.siteMetadata.drupalSiteURL,
        path: node.attribs['src'],
      };

      // If the path exists and this IS a relative URL.
      // (i.e. /some/page vs https://some-page.com)
      if (srcData.path && srcData.path.match(/^\//)) {
        // Add the Drupal URl to the relative URL.
        node.attribs.src = srcData.site + srcData.path;
        return convertNodeToElement(node, index);
      }
    }
  };

  const options = {
    decodeEntities: true,
    transform
  };

  return (
    <div
      className={classnames(styles.wysiwyg, {
        [className]: className
      })}
    >
      {/*
        In some cases we want to dangerously render the body contents.
        A good use case for this is if we want to render a third party HTML <form>
        and tell React to leave that form alone.
      */}
      {dangerouslyRender && <div dangerouslySetInnerHTML={{ __html: body }} />}
      {/* eslint-disable-next-line new-cap */}
      {!dangerouslyRender && ReactHtmlParser(body, options)}
      {children}
    </div>
  );
};

WYSIWYG.propTypes = {
  /* HTML as a string to be rendered, from a WYSIWYG field. */
  body: PropTypes.string,
  /* React elements to be rendered. */
  children: PropTypes.node,
  className: PropTypes.string,
  dangerouslyRender: PropTypes.bool
};

export default WYSIWYG;
